import {Component} from 'vue-property-decorator'
import {I18nComponent} from '@/components/I18nComponent'
import {VNode} from 'vue'

@Component({name: 'History'})
export default class extends I18nComponent {

  public render(): VNode {
    return(
      <div class="text-center text-danger">
        <span>Page currently unavailable</span>
      </div>
    )
  }
}